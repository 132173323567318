import styles from "./categories-modal.module.scss";
import {useEffect, FC} from "react";
import {IModalComponent} from "@/services/types/types";
import {AnimatePresence, motion} from "framer-motion";
import ModalHeader from "../modal/modal-header/modal-header";
import ModalClose from "../modal/modal-close/modal-close";
import {createPortal} from "react-dom";

const CategoriesModal: FC<IModalComponent> = ({
                                                isOpen,
                                                children,
                                                title,
                                                onClose,
                                              }) => {
  const modalRoot = document.getElementById("modal-categories");
  const dropIn = {
    hidden: {
      left: "-100%",
      opacity: 0,
    },
    visible: {
      left: "0",
      opacity: 1,
    },
    exit: {
      left: "-100%",
      opacity: 0,
    },
  };
  
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose && onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
    // eslint-disable-next-line
  }, []);
  const stopClick = (e) => {
    e.stopPropagation();
  };
  return createPortal(
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="modal"
            className={styles.modal}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={stopClick}
          >
            <ModalHeader title={title} onClose={onClose} goBack/>
            <ModalClose onClose={onClose}/>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>,
    modalRoot!
  );
};

export default CategoriesModal;
