import styles from "./catalog-modal-item.module.scss";
import Image from "next/image";
import {FC} from "react";
import {ICatalogMiniItem} from "@/services/types/types";
import {useOpenModal} from "@/services/hooks/useOpenModal";
import {useRouter} from "next/navigation";
import {CatalogCategoriesModal} from "../../catalog-categories-modal/catalog-categories-modal";

interface ICatalogModalItem {
  item: ICatalogMiniItem;
  onCloseParent: () => void;
}

export const CatalogModalItem: FC<ICatalogModalItem> = (props) => {
  const {item, onCloseParent} = props
  const router = useRouter();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const handleClickLink = () => {
    if (item.depths) {
      handleOpenModal();
    } else {
      router.push(`/catalog/${item.code}`);
      handleCloseModal();
      onCloseParent();
    }
  };
  return (
    <>
      {showModal && (
        <CatalogCategoriesModal
          isOpen={showModal}
          onClose={handleCloseModal}
          title={"Все категории"}
          thisCategory={item}
          onCloseParent={onCloseParent}
        />
      )}
      <div className={styles.item}>
        <button onClick={handleClickLink} className={styles.link}>
          <Image
            className={styles.img}
            alt={item.name}
            src={`https://ohotaktiv.ru${item.picture}`}
            width={1000}
            height={1000}
          />
          {item.name}
        </button>
      </div>
    </>
  );
};
