import styles from "./catalog-categories-modal.module.scss";
import { FC, useState } from "react";
import CategoriesModal from "@/components/categories-modal/categories-modal";
import { useRouter } from "next/navigation";
import { useOpenModal } from "@/services/hooks/useOpenModal";

interface ICatalogCategoriesModal {
  isOpen: boolean;
  onClose: () => void;
  thisCategory: any;
  title: string;
  onCloseParent: () => void;
}

export const CatalogCategoriesModal: FC<ICatalogCategoriesModal> = ({
  isOpen,
  onClose,
  title,
  thisCategory,
  onCloseParent,
}) => {
  const router = useRouter();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const [choosedCategory, setChoosedCategory] = useState();
  const handleClick = (category: { code: string; depths: any }) => {
    if (category === thisCategory) {
      onClose();
      onCloseParent();
      router.push(`/catalog/${category.code}`);
      setTimeout(() => {
        document.body.style.overflowY = "auto";
      }, 100);
    } else if (category.depths) {
      setChoosedCategory(category);
      handleOpenModal();
      setTimeout(() => {
        document.body.style.overflowY = "auto";
      }, 100);
    } else {
      onClose();
      onCloseParent();
      router.push(`/catalog/${category.code}`);
      setTimeout(() => {
        document.body.style.overflowY = "auto";
      }, 100);
    }
  };

  return (
    <>
      {showModal && (
        <CatalogCategoriesModal
          isOpen={showModal}
          onClose={handleCloseModal}
          title={thisCategory.name}
          thisCategory={choosedCategory}
          onCloseParent={onCloseParent}
        />
      )}
      <CategoriesModal isOpen={isOpen} title={title} onClose={onClose}>
        <div className={styles.huyaper}>
          <div className={styles.list}>
            <button
              onClick={() => handleClick(thisCategory)}
              className={styles.itemTitle}
            >
              {thisCategory.name}
            </button>
            {Object.keys(thisCategory.depths).map((category, index: number) => {
              return (
                <button
                  onClick={() => handleClick(thisCategory.depths[category])}
                  key={`${index + "button" + "catalog" + "categoriesModal"}`}
                  className={styles.item}
                >
                  {thisCategory.depths[category].name}
                </button>
              );
            })}
          </div>
        </div>
      </CategoriesModal>
    </>
  );
};
