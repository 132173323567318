import { CatalogMiniItem } from "@/components/catalog-mini-item/catalog-mini-item";
import styles from "./header-catalog.module.scss";
import React, { FC, ForwardedRef } from "react";
import { CloseIcon } from "@/components/icon";
import classNames from "classnames";
import { ICatalogMiniItem } from "@/services/types/types";


interface IHeaderCatalog {
  catalog: Array<ICatalogMiniItem>;
  isOpenCatalog: boolean;
  openCatalog: () => void;
  ref: ForwardedRef<HTMLDivElement>;
}

const HeaderCatalog: FC<IHeaderCatalog> = React.forwardRef((props, ref) => {
  const { catalog, isOpenCatalog, openCatalog } = props;
  const catalogClassnames = classNames({
    [styles.catalog]: true,
    [styles.catalogOpen]: isOpenCatalog,
  });
  return (
    <div className={catalogClassnames} ref={ref}>
      <div className={styles.catalogWrap}>
        <p className={styles.title}>Категории</p>
        <div className={styles.catalogList}>
          {catalog.map((item: ICatalogMiniItem, index: number) => {
            return <CatalogMiniItem
              key={`${index + "CatalogMiniItem" + "HeaderCatalog"}`}
              item={item}
              closeHeader={openCatalog}
            />;
          })}
        </div>
        <button onClick={openCatalog} className={styles.close}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
});
HeaderCatalog.displayName = "HeaderCatalog";
export default HeaderCatalog;