"use client";
import styles from "./header-catalog-button.module.scss";
import { BurgerIcon } from "@/components/icon";
import ButtonComponent from "@/components/button/button";
import React, { FC, ForwardedRef } from "react";
interface IHeaderCatalogButton {
  isOpen: boolean;
  setIsOpen: () => void;
  ref?: ForwardedRef<HTMLButtonElement>;
}
const HeaderCatalogButton: FC<IHeaderCatalogButton> = React.forwardRef((
  { isOpen, setIsOpen }, ref: any
) => {
  return (
    <ButtonComponent onClick={setIsOpen} className={styles.hamburger} ref={ref}>
      <>
        <BurgerIcon isActive={isOpen} className={`${styles.icon} ${isOpen && styles.active}`} />
        {/* <div className={styles.burger_icon}></div> */}
        <>Каталог</>
      </>
    </ButtonComponent>
  );
});
HeaderCatalogButton.displayName = "HeaderCatalogButton";
export default HeaderCatalogButton;
