import Link from "next/link";
import styles from "./catalog-mini-item.module.scss";
import Image from "next/image";
import { FC } from "react";
import { ICatalogMiniItem } from "@/services/types/types";

export const CatalogMiniItem: FC<{
  item: ICatalogMiniItem;
  closeHeader: () => void;
}> = ({ item, closeHeader }) => {
  return (
    <div className={styles.item} onClick={() => closeHeader()}>
      <Link prefetch={false} className={styles.link} href={`/catalog/${item.code}`}>
        <Image
          className={styles.img}
          alt={item.name}
          src={`https://ohotaktiv.ru${item.picture}`}
          width={1000}
          height={1000}
        />
        {item.name}
      </Link>
    </div>
  );
};
