"use client";

import HeaderActions from "../header-actions/header-actions";
import HeaderLogo from "../header-logo/header-logo";
import HeaderSearch from "../header-search/header-search";
import styles from "./header-bottom.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { useBodyScrollLock } from "@/services/hooks/useBodyScrollLock";
import { ICatalog } from "@/services/types/types";
import { HeaderMenu } from "../header-menu/header-menu";
import { HeaderNav } from "../header-nav/header-nav";
import HeaderCatalogButton from "../header-catalog-button/header-catalog-button";
import { ArrowSearch } from "@/components/icon";
import { useWindowSize } from "@/services/hooks/useWindowSize";
import HeaderCatalog from "../header-catalog/header-catalog";

export const HeaderBottom: FC<ICatalog> = ({ catalog }) => {
  const [isOpenCatalog, setIsOpenCatalog] = useState<boolean>(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useBodyScrollLock();
  const [windowSize, setWindowSize] = useWindowSize();
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const headerCatalogRef = useRef<any>(null);
  const headerCatalogButtonRef = useRef<any>(null);
  const headerSearchRef = useRef<HTMLDivElement>(null);
  const handleOpen = () => {
    setIsLocked(!isLocked);
    setIsOpenCatalog(!isOpenCatalog);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerCatalogRef.current &&
        !headerCatalogRef.current.contains(event.target as Node) &&
        !!headerCatalogButtonRef.current &&
        !headerCatalogButtonRef.current?.contains(event.target as Node)
      ) {
        if (
          !!headerSearchRef.current &&
          headerSearchRef.current?.contains(event.target as Node)
        ) {
          setIsSearchOpen(!isSearchOpen);
          if (true) {
            document.body.style.overflow = "hidden";
          }
        }
        setIsLocked(false);
        setIsOpenCatalog(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerCatalogRef, headerCatalogButtonRef]);

  return (
    <>
      <div className={styles.bottomWrap}>
        {isSearchOpen && windowSize <= 767 ? (
          <button
            onClick={() => setIsSearchOpen(false)}
            className={styles.close_search}
            title="Назад"
          >
            <ArrowSearch className={styles.close_icon} fill="#1D1D1D" />
          </button>
        ) : (
          <HeaderMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
        )}
        <HeaderLogo />
        <HeaderCatalogButton
          isOpen={isOpenCatalog}
          setIsOpen={handleOpen}
          ref={headerCatalogButtonRef}
        />
        <HeaderSearch
          isOpen={isSearchOpen}
          setIsOpen={setIsSearchOpen}
          ref={headerSearchRef}
        />
        <HeaderActions />
      </div>
      <HeaderCatalog
        catalog={catalog}
        openCatalog={handleOpen}
        isOpenCatalog={isOpenCatalog}
        ref={headerCatalogRef}
      />
      <HeaderNav setIsOpenMenu={setIsOpenMenu} catalog={catalog} />
    </>
  );
};
