import styles from "./catalog-modal.module.scss";
import { FC } from "react";
import CategoriesModal from "@/components/categories-modal/categories-modal";
import { ICatalogMiniItem } from "@/services/types/types";
import { CatalogModalItem } from "./catalog-modal-item/catalog-modal-item";

interface ICatalogModal {
  isOpen: boolean;
  onClose: () => void;
  catalog: any;
}

export const CatalogModal: FC<ICatalogModal> = ({
  isOpen,
  onClose,
  catalog,
}) => {
  return (
    <CategoriesModal isOpen={isOpen} title={"Категории"} onClose={onClose}>
      <div className={styles.list}>
        {catalog.map((item: ICatalogMiniItem, index: number) => {
          return (
            <CatalogModalItem
              key={`${index + "CatalogModalItem" + "CatalogModal"}`}
              item={item}
              onCloseParent={onClose}
            />
          );
        })}
      </div>
    </CategoriesModal>
  );
};
